.menuRow {
  text-align: center;
  width: 100%;
}

.navbar {
  transition-timing-function: ease-in !important;
  transition: ease-in 2s !important;
  padding: 0;
  /* height: 10vh; */
}

.logoMain {
  margin: 0;
  background-color: antiquewhite;
  padding: 10px;
}

.logoImage {
  height: 5vh;
}

.menuItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconItems a {
 filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); 
}

.icon {
 color: #b68c8c;
}

.icon:hover {
  color: #634b4b;
 }

.navbar-brand {
  width: fit-content;
  margin: 0;
}

.nav-link {
  padding-left: 2vw !important;
  padding-right: 2vw !important;
}

.nav-link a {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8vw;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  color: #d8c3c3;
  text-decoration: none;
}

.nav-link a:hover {
  color: #705a5a;
}

.navIconsMedium {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarColor {
  /* background: linear-gradient(#f5f5f5 0%, #fdfdfd 95%, #dc891c38 100%); */
  background-color: #553737;
  border-bottom: 2px solid rgb(0, 0, 0);
}

.menuItemsSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.navbar-toggler {
  border-color: 1px solid rgb(166 156 138)!important;
  font-size: unset !important;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    justify-content: center;
    display: flex;
  }

  
}

@media (max-width: 1200px) {
  .nav-link a {
    font-size: 0.9vw;
  }
}

@media (max-width: 980px) {
 
}

@media (max-width: 768px) {
  .nav-link a {
    font-size: 1.5vw;
  }
}
