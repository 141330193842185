@import url("https://fonts.googleapis.com/css?family=Modak");

.homeContainer {
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  background: #2b1d1d98;
  color: #fafafa;
  position: relative;
  justify-content: space-around;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  display: flex;
}

.bgImgHome {
  background-image: url("./img/homeImg.jpg");
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.homeContentCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headingContainer {
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 20px 0px;
  width: 70%;
  border: 1px #b99292 dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fafafa01;
  backdrop-filter: blur(3px);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(0, 0, 0, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

#logoImg {
  width: 37vw;
  filter: drop-shadow(3px 3px 1px black) opacity(0.8) blur(0.5px);
}

.logoTextDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.logoTextDesc h2 {
  font-size: 2.4vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  color: #fafafa96;
  line-height: 34px;
}

#logoTextMain {
  position: absolute;
  left: 8vw;
  top: 2vh;
  font-family: "Carattere", cursive;
  font-size: 9vw;
  padding: 10px;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(255, 255, 255, 0.184);
  background-image: url("./img/textBG.jpeg");
  background-size: contain;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

#logoTextSecond {
  position: absolute;
  top: 22vh;
  left: 20vw;
  font-family: "Carattere", cursive;
  font-size: 8.2vw;

  padding: 10px;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(255, 255, 255, 0.184);
  background-image: url("./img/textBG.jpeg");
  background-size: contain;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.formContainer {
  background: rgba(179, 160, 126, 0.82);
  background-size: contain;
  width: 100%;
  max-width: 70vw;
  padding: 25px;
  text-align: center;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.397) 0px 10px 20px,
    rgba(0, 0, 0, 0.521) 0px 6px 6px;
  border: 1px dashed #633737;
}

.formContainer h4 {
  font-size: 2vw;
  font-weight: 500;
  color: rgba(249, 246, 235, 0.91);
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.formContainer p {
  font-size: 1vw;
  font-style: italic;
}

.formContainer p span {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  color: #633737;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.112);
  text-decoration: underline;
}

.form-control {
  border: 1px dashed rgba(136, 96, 88, 0.52);
  border-radius: 1px;
  background-color: rgba(111, 57, 46, 0.52);
  font-size: 10px;
  max-width: 100%;
  color: #000000 !important;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.715);
  font-size: 0.7vw;
}

.btnCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnCol .btn-success {
  border-radius: 1px;
  padding: 8px 25px;
  background-color: rgba(65, 121, 54, 0.77);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

.btnCol .btn-success:hover {
  background-color: rgba(125, 162, 117, 0.77);
}

@media (max-width: 1200px) {
  #logoTextSecond {
    top: 20vh;
  }
}

@media (max-width: 980px) {
  #logoTextSecond {
    top: 16vh;
  }
}

@media (max-width: 768px) {
  .headingContainer {
    justify-content: left;
  }

  #logoTextSecond {
    top: 12vh;
  }

  .logoTextDesc {
    position: absolute;
    bottom: 0;
    left: 15%;
    /* align-items: flex-end;
    justify-content: left; */
    margin-top: 0px;
  }

  .logoTextDesc h2 {
    font-size: 2.6vw;
    line-height: 34px;
  }

  .formContainer {
    margin-top: 10vh;
  }

  .formContainer h4 {
    font-size: 3vw;
  }
}

@media (max-width: 500px) {
  #logoTextSecond {
    top: 8vh;
  }
}

@media (max-width: 400px) {
  #logoTextSecond {
    top: 6.5vh;
  }
}
