@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Patrick+Hand+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carattere&display=swap');

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  position: relative;
  background-color: #f89e27;
  text-align: center;
  letter-spacing: 1px;
  overflow-x: hidden;
  color: #fafafa;
  scrollbar-width: none;
  font-family: 'Montserrat', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

p {
  margin-bottom: 0 !important;
}

.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.align {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.row {
  --bs-gutter-x: 0 !important;
}

.icon {
  font-size: 30px;
  
}



@media (max-width: 800px) {
  .icon {
    font-size: 25px;
  }
}

@media (max-width: 400px) {
  .subHeading {
    font-size: 6vw;
  }
  .icon {
    font-size: 20px;
  }
}
