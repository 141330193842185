.juniorContainer {
  height: 100%;
  padding: 5vw;
  position: relative;
  flex-direction: column;
  color: #fafafa;
  background: url("./img/BakgrundIntervju.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Montserrat", sans-serif;
}

.bgJunior {
  background: linear-gradient(to top, #b124b6b3, #b124b6b3);
  height: 100vh;
  width: 100%;
  top: 0;
  position: absolute;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.interviewTextRow {
  flex-direction: column;
}

.interviewTextCol {
  margin: 2.5vh 0;
  font-size: 0.8vw;
  padding: 10px;
  border-radius: 3px;
  font-style: italic;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#intervjuImg {
  border-radius: 50%;
  width: 80%;
  box-shadow: rgb(153 146 112 / 52%) 0px 19px 10px -10px;
  border: 4px solid rgba(0, 0, 0, 0.51);
}

.greenBg {
  background-color: rgba(99, 174, 76, 0.509);
  border-left: 4px solid #fafafa;
  text-align: left;
}

.redBg {
  background-color: rgba(174, 83, 76, 0.509);
  border-top: 4px solid #fafafa;
}

.yellowBg {
  background-color: rgba(181, 176, 75, 0.509);
  border-left: 4px solid #fafafa;
  text-align: left;
}

.blueBg {
  background-color: rgba(75, 160, 181, 0.509);
  border-top: 4px solid #fafafa;
}


@media (max-width: 1000px) {
  .interviewTextCol {
    font-size: 1vw;
  }
}

@media (max-width: 768px) {

  .juniorContainer {
    height: 100%;
  }

  .interviewTextCol {
    font-size: 1.5vw;
  }
}
