@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap");

.footerContainer {
  height: 100%;
  background: #d8cabb;
  border-top: 2px solid #fafafa;
  color: #fafafa;
  position: relative;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  padding: 50px;
}

.footerContainer h4 {
  font-size: 10px;
  font-weight: 900 !important;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footerContainer p {
  margin-bottom: 0.2rem;
  font-weight: 400 !important;
  font-size: 10px;
}

.footerContainer p span {
  font-weight: 100 !important;
  font-size: 9px;
}

.footerContainer a {
  color: white !important;
}

.footerContainer a:hover {
  color: #f7a130 !important;
}

.white {
  text-align: left;
}

.iconItems a{
  color: #d8c3c3;
}

.iconItems a:hover{
  color: #998181;
}

.descCol {
  display: flex;
  justify-content: start;
}

.iconCol {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5vw;
}

#footerLogo {
  filter: drop-shadow(1px 1px 2px rgb(246, 246, 246)) opacity(0.6);
  height: 50%;
  width: 10vw;
}

@media (max-width: 460px) {
  #footerLogo {
    width: 15vw;
  }
}
