@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;900&display=swap");

.infoSchemaContainer {
  min-height: 100vh;
  position: relative;
  padding: 50px;
  color: #fafafa;
  font-family: "Montserrat", sans-serif;
}

.bgImgSchedule {
  background: url("./img/Bakgrund2VinprovningRöd.jpg");
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.infoContentRow {
  justify-content: space-evenly;
}

.infoContentCol {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

#contentSpan {
  text-align: left !important;
  font-size: 0.8vw;
}

.infoContentCol:first-child {
  background-color: rgba(184, 169, 141, 0.412);
  /* margin-left: 50px; */
  padding: 25px;
  font-size: 1vw;
  font-weight: 200;
  font-style: italic;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.infoContentCol:nth-child(2) img {
  border-radius: 50%;
  width: 100%;
  box-shadow: rgb(153 146 112 / 52%) 0px 19px 10px -10px;
  border: 4px solid rgba(0, 0, 0, 0.51);
}

.headingSecondary {
  font-weight: 700;
  font-size: 4vw;
  line-height: 50px;
  /* color: #ffd1d2; */
}

b {
  font-weight: 700;
}

@media (max-width: 768px) {
  .infoSchemaContainer {
    height: 100%;
  }

  .bgImgSchedule {
    height: 100%;
  }

  .headingSecondary {
    font-size: 5vw;
  }

  .infoContentCol:first-child {
    font-size: 1.5vw;
    margin-bottom: 2vh;
  }

  .infoContentCol:last-child {
    flex: 1;
  }

  .infoContentCol:nth-child(2) img {
    width: 50%;
  }
  #contentSpan {
    font-size: 1.8vw;
  }
}
