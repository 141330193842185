.provningarContainer {
  min-height: 100vh;
  height: 100%;
  padding: 5vh 5vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fafafa;
  background-color: #d8cabb;
  font-family: "Montserrat", sans-serif;
}

.provningContainer {
  height: 20vh;
  width: 80%;
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.provningHeading {
  color: #6c5657;
  font-weight: 700;
}

.splashImg {
  position: absolute;
  left: 0;
  bottom: 20%;
  width: 15%;
}

.provningarContentCol h3 {
  color: #8e5f5f;
  font-size: 1.5vw;
  font-weight: 700;
  text-decoration: underline;
}

.hideList {
  position: relative;
  transition: all 1s;
  width: 100%;
  height: 100%;
  filter: sepia(0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  z-index: 1;
}

.hideList h5 {
  display: block;
  font-family: "Carattere", cursive;
  font-size: 3vw;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.hideList:hover {
  transform: scale(1.2);
  filter: sepia(0);
}

.showList {
  position: relative;
  background: none !important;
  width: 100%;
  cursor: pointer;
}

.showList h5 {
  display: none;
}

.listBehind {
  position: absolute;
  width: 100%;
  z-index: 0;
}

.listBehind .row {
  border-radius: 3px;
  margin: 0.7vh 0.5vw;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
}

.listBehind .row:nth-child(odd) {
  background-color: #bd9898;
}

.listBehind .row:nth-child(even) {
  background-color: rgba(189, 152, 152, 0.48);
}

.listBehind .col {
  border-right: 1px solid #e6d7d7;
  font-weight: 600;
  font-size: 1vw;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.listBehind .col-6:nth-child(2) {
  border-right: none;
}

.corkScrewCol {
  padding: 2px;
}

.corkScrew {
  width: 8%;
}

.corkScrewOpen {
  width: 18%;
}

.arrowCol {
  display: flex;
  justify-content: flex-start;
  font-size: 1.5vw;
}

.flagCol {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  height: 2.5vh;
}

.flagFrance {
  height: 2vh;
}

#fordjupning {
  background: url("./img/wines-1761613_1280.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#vinlander {
  background: url("./img/wine-1938924_640.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#druvor {
  background: url("./img/maja-petric-vGQ49l9I4EE-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#introduktion {
  background: url("./img/scott-warman-h4AGlo55tTA-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


@media (max-width: 768px) {
  .provningarContainer {
    height: 100%;
  }

  .provningContainer {
    margin-bottom: 5vh;
  }

  .provningarContentCol h3 {
    font-size: 2.5vw;
  }

  .listBehind .col {
    font-size: 2vw;
  }

  .hideList h5 {
    font-size: 5vw;
  }

  .flag {
    height: 2.5vw;
  }
  
  .flagFrance {
    height: 2vw;
  }
}

@media (max-width: 460px) {
  .provningContainer {
    height: 15vh;
  }
}